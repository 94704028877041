import React from "react"
import PropTypes from "prop-types"

import CommonButton from "./CommonButton"
import "./Categories.css"

function Categories({ heading, linkText, link, color, image }) {
  return (
    <categories>
      <div className="category-item">
        <a
          href={link}
          className="category-highlights"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="category-overlay">
            <div className="category-overlay-title">{heading}</div>
            <div style={{ position: "absolute", left: "30px", bottom: "20px" }}>
              <CommonButton text={linkText} link={link} color={color} />
            </div>
          </div>
        </a>
      </div>
    </categories>
  )
}

Categories.defaultProps = {
  heading: `Travel`,
  linkText: `View Posts`,
  link: `/blog`,
  color: `white`,
  image: `https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png`,
}

Categories.propTypes = {
  heading: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
}

export default Categories
