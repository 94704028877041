import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/layout"
import Instagram from "../components/instagram"
import SEO from "../components/seo"
import CommonButton from "../components/CommonButton"
import Categories from "../components/Categories"
import "./index.css"

import stream from "../images/static/switzerlandstream.jpg"

const IndexPage = () => (
  <Layout isHome={true}>
    <SEO title="Home" />
    <div className="front-cover">
      <div className="front-cover-content">
        <div className="front-cover-content-text">
          <h1 className="front-title">Hey, I'm Jesse!</h1>
          <h1 className="front-title front-main-title">
            Follow my story around the world{" "}
            <span class="front-cover-text-color-special">.</span>
          </h1>
          <p>Thanks for visiting learn more below</p>

          <CommonButton text="LEARN MORE" link="/about" color="black" />
        </div>
        <div className="front-bg-element"></div>
      </div>
      <div className="front-cover-content right">
        <div className="flex-container front-link-container">
          {" "}
          <a className="front-cover-right-link first-link-item">
            <div className="front-button">View Blog</div>
          </a>
          <a className="front-cover-right-link cover-about-link">
            <div className="front-button">About Me</div>
          </a>
        </div>
      </div>
    </div>
    <div className="categories">
      <div className="flex-container relative">
        <div className="categories-title-wrapper">
          <h2 className="categories-title">
            Discover <span className="title-underline">My Stories</span>
          </h2>
        </div>
      </div>
      <div className="category-container">
        <Categories
          heading="Travel"
          linkText="VIEW POSTS"
          link="/blog"
          color="white"
          image="https://assets.website-files.com/5b22aea87ac6053b4c218ada/5b2389306f08f75e1ee847cf_pexels-photo-1149314.jpeg"
        />
        <Categories
          heading="Personal"
          linkText="VIEW POSTS"
          link="/Personal"
          color="white"
          image="https://assets.website-files.com/5b22aea87ac6053b4c218ada/5b238937dcd52839e790c787_pexels-photo-697244.jpeg"
        />
        <Categories
          heading="Africa"
          linkText="VIEW POSTS"
          link="/Africa"
          color="white"
          image="https://assets.website-files.com/5b22aea87ac6053b4c218ada/5b2387f9932c87b6152b2f43_pexels-photo-1049500.jpeg"
        />
        <Categories
          heading="Asia"
          linkText="VIEW POSTS"
          link="/Asia"
          color="white"
          image="https://assets.website-files.com/5b22aea87ac6053b4c218ada/5b23880813f80e0af972dec7_pexels-photo-236148.jpeg"
        />
      </div>
      <div className="front-bg-element lower"></div>
      <div className="visit-blog-wrapper flex-container relative">
        <div className="discover-blog-title">
          Want to discover my complete blog?
        </div>{" "}
        <CommonButton text="VISIT BLOG" link="/blog" color="black" />
      </div>
    </div>
    <div className="featured-story">
      <div className="featured-story-wrapper">
        <a className="featured-story-image" style={{}}></a>
        <div className="featured-story-image-overlay-wrapper flex-container">
          <div
            className="featured-story-image-overlay"
            style={{
              backgroundImage:
                "url(" +
                "https://assets.website-files.com/5b22aea87ac6053b4c218ada/5b238a1213f80ee06072e008_pexels-photo-459225.jpeg" +
                ")",
            }}
          ></div>
          <div
            className="featured-story-image-overlay"
            style={{
              backgroundImage: `url(${stream})`,
            }}
          ></div>
        </div>
      </div>
      <div className="featured-story-wrapper-right">
        <div className="featured-story-text-wrapper">
          <h1 className="featured-story-text">Featured Story</h1>
          <h1 className="featured-story-title">
            Most unforgettable moments of 2019.
          </h1>
          <p>
            What a year its been. Checking off a few more countries and adding
            countless memories.
          </p>
          <CommonButton text="Learn More" link="/about" color="black" />
        </div>
      </div>
    </div>
    {/*<Instagram /> */}
  </Layout>
)

export default IndexPage
